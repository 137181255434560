<div class="footer">
  <div class="custom-container">
    <div class="row">
      <div class="col-12">
        <div class="footer-content text-center">
          <div class="footer-title">
            <div class="img-wrapper">
              <img src="https://s3.amazonaws.com/creditsnapimages/ABC/EqualHousingLender.png" alt="">
            </div>
            <div class="img-wrapper">
              <!-- <img src="/assets/images/ncua.png" alt="footer icon" class="img-fluid footer-img"> -->
            </div>
            <p class="mb-0">
              Federally insured by NCUA. Equal Housing Opportunity.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="copyright">
  <div class="custom-container">
    <div class="row">
      <div class="col-12">
        <div class="copyright-details">
          <p class="mb-0">&copy; {{currentYear}} {{appConfig.footerConfig.footerBottom.copyRight}}</p>
          <a href="tel: {{appConfig.footerConfig.footerBottom.phone}}" class="d-flex">
            <span class='material-icons me-1'>phone</span>
            {{appConfig.footerConfig.footerBottom.phone}}</a>
        </div>
      </div>
    </div>
  </div>

</div>

