import { Component, Inject, OnInit } from "@angular/core";
import { APP_CONFIG, APP_ENV_CONFIG } from "@creditsnap/app-config";

@Component({
	selector: "wecu-header",
	templateUrl: "./header.component.html",
	styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit {
	hideButton: boolean = false;

	constructor(
		@Inject(APP_CONFIG) public appConfig: any,
		@Inject(APP_ENV_CONFIG) public environment: any
	) {
	}

	ngOnInit(): void {
	}

	buttonHide(type: any) {
		this.hideButton = type === "showClass";
	}

}
