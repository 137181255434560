<div class="main-header">
	<div class="header-top" [ngClass]="{'show': hideButton, '': !hideButton}"
		 [style.background-color]="appConfig?.headerConfig?.BGColor"
		 [style.color]="appConfig?.headerConfig?.FontColor">
		<div class="custom-container">
			<div class="row">
				<div class="col-12">
					<div class="top-header-content" id="navbarSupportedContent">
						<ul class="mb-0">
							<button class="close d-md-none" (click)="buttonHide('hideCalss')">
								x
							</button>
							<li>
								<a href="tel: {{appConfig.phoneNumber}}"
								   [style.color]="appConfig?.headerConfig?.FontColor">
									<span class='material-icons me-1'>phone</span>
									{{appConfig.phoneNumber}} </a>
							</li>
							<li>
								<a href="/myapp">My Application</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="header">
		<div class="custom-container">
			<div class="row">
				<div class="col-12">
					<div class="d-flex align-items-center justify-content-between">
						<div class="img-wrapper">
							<a [href]="appConfig?.logoUrl || environment.websiteUrl" target="_blank">
								<img alt="Header Logo" class="img-fluid brand" src="{{appConfig.logoName || environment.institutionId}}">
							</a>
						</div>
						<button class="navbar-toggler navbar-light d-md-none" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" (click)="buttonHide('showClass')">
							<span class="navbar-toggler-icon"></span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
