export const environment = {
  production: true,
  isMockEnabled: false,  // You have to switch this, when your real back-end is done
  domain: 'apply.wecu.com',
  baseUrl: 'https://apply.wecu.com',
  apiUrl: 'https://prod-api-elb.creditsnap.com/csb2b',
  websiteUrl: 'https://wecu.com',
  channel: 'web',
  institutionId: 'WECU',
  provisionApiUrl: 'https://xo7mcoan2f.execute-api.us-east-1.amazonaws.com/cs/provisions',
  institutionConfig: {
    minLoanAmount: 5000
  },
  s3Images: 'https://s3.amazonaws.com/creditsnapimages/', // 'https://s3.amazonaws.com/creditsnapimages/',
  client: {
    clientAppStatusURL: '',
    secretKey: '',
  },
  auth: {
    basic: 'Basic Y3NfdHJ1c3RlZF9jbGllbnRfcHJvZDpqM3JYSkE2UEx5SkVNSm1N',
    scope: 'read write trust',
    grantType: 'password',
    interceptorSkipHeader: 'X-Skip-Interceptor'
  },
  debug: false,
  type: 'PROD',
  google_siteKey: '6LfI-SUbAAAAAAuWGZ8BNLvT2a2Ewi-OoSBbTJhS',
  privacyPolicyHref: 'https://www.wecu.com/security/',
  incomeVerificationLink: 'https://api-stg.measureone.com/v3/js/m1-link-2021042000.js',
  socketBaseUrl: 'wss://m2pbbyg907.execute-api.us-east-1.amazonaws.com/production',
};
