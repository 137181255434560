import {Component, Inject} from '@angular/core';
import {APP_CONFIG} from "@creditsnap/app-config";

@Component({
  selector: 'creditsnap-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'wecu';

  constructor(
    @Inject(APP_CONFIG) public appConfig: any) {

  }

  // constructor() {
  //   setTimeout(() => {
  //     this.changeTheme('RED', 'PINK');
  //   }, 2000);
  // }
  //
  // changeTheme(primary: string, secondary: string) {
  //   document.documentElement.style.setProperty('--first-color', primary);
  //   document.documentElement.style.setProperty('--second-color', secondary);
  // }
}
